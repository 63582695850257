import type { RouteRecordRaw } from "vue-router"
import auth from "@/middlewares/auth"
import review from "@/middlewares/review"

const reviewRoutes: RouteRecordRaw[] = [
  {
    path: "/reviews",
    name: "review-management",
    component: () => import("@/views/reviews/ReviewsManagement.vue"),
    meta: {
      middleware: [auth, review],
      title: "reviews.page_title",
    },
  },
]

export default reviewRoutes
