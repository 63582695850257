import http from "@/http-common"
import type { IGoogleButton, IGoogleSign } from "@/types/Account.types"

class GoogleService {
  getAuthenticationUrl(input: IGoogleButton): Promise<any> {
    return http.post(`/google/auth-url`, input)
  }

  authenticateWithGoogle(input: IGoogleSign): Promise<any> {
    return http.post(`/google/auth`, input)
  }

  getGoogleEstablishments(): Promise<any> {
    return http.get(`/auth/google/locations`)
  }
}

export default new GoogleService()
