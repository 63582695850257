import type { RouteRecordRaw } from "vue-router"
import auth from "@/middlewares/auth"
import admin from "@/middlewares/admin"

import { PageTitleParamsType } from "@/types/Router.types"

const userRoutes: RouteRecordRaw[] = [
  {
    path: "/profile",
    name: "user-profil",
    component: () => import("@/views/user/Profil.vue"),
    meta: {
      middleware: [auth],
      title: "profile.page_title",
    },
  },
  {
    path: "/usermanagement",
    name: "user-management",
    component: () => import("@/views/user/UsersManagement.vue"),
    meta: {
      middleware: [auth],
      title: "usermanagement.page_title",
    },
  },
  {
    path: "/usermanagement/user/:id?/:user?",
    name: "user-management-details",
    component: () => import("@/views/user/Create.vue"),
    meta: {
      middleware: [auth, admin],
      title: "usermanagement/user.page_title",
      hasTitleParams: {
        type: PageTitleParamsType.PARAM,
        name: "user",
      },
    },
  },
  {
    path: "/newuser",
    name: "user-create",
    component: () => import("@/views/user/Create.vue"),
    meta: {
      middleware: [auth, admin],
      title: "newuser.page_title",
    },
  },
]

export default userRoutes
