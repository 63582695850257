import { defineStore } from "pinia"
import type { AllDiffuser, DiffuserType } from "@/static/Diffuser"
import DiffuserDataService from "@/services/DiffuserDataService"
import { useAuthenticationStore } from "./authentication"
import type {ICheckConnectDiffuser, IConnectDiffuser, IUrlDiffuser} from "@/types/Diffuser.types"
import { useAlertStore } from "./alert"
import { AlertType } from "@/static/Alert"
import { i18n } from "@/i18n"

export const useDiffuserStore = defineStore("diffuser", {
  state: () => ({
    connectedDiffusers: [] as { diffuser: AllDiffuser; connected: Boolean }[],
    selectedDiffuser: {}
  }),

  actions: {
    /**
     * Get diffuser type url connexion.
     * The url can be null (Uberall didn't find url)
     *
     * @param establishmentId
     * @param type
     * @param redirect
     * @param successCallback
     * @returns
     */
    getDiffuserConnexionUrl(
      establishmentId: number,
      type: DiffuserType,
      redirect: ConnectRedirectionUrl,
      successCallback: (connect: { connect_url: string; claim_url: string }) => void,
      showError?: Boolean
    ): Promise<any> {
      // Instantiate connect diffuser object
      const diffuser: IUrlDiffuser = {
        establishment_id: establishmentId,
        diffuser_type: type,
        redirect_to_url: redirect + type,
        language: "fr",
      }

      if (redirect === import.meta.env.VITE_CONNECT_GOOGLE_REDIRECT_URL) {
        diffuser.redirect_to_url = redirect
      }

      const authStore = useAuthenticationStore()

      // Create promise
      const promise = DiffuserDataService.getUrl(diffuser, authStore.currentHeaders).then(
        (res) => {
          if (res.data.data) {
            successCallback(res.data.data)
          }
        },
        (e) => {
          const alertStore = useAlertStore()
          if (showError) {
            switch (e.response.status) {
              case 401:
                break
              case 409:
                alertStore.push(AlertType.error, i18n.global.t("common.errors.hubo_server_error"), e.message)
                break
              case 503:
                alertStore.push(AlertType.error, i18n.global.t("common.errors.uberall_server_error"), e.message)
                break
              default:
                alertStore.push(AlertType.error, i18n.global.t("common.errors.default"), e.message)
                break
            }
          }
        }
      )
      return promise
    },

    /**
     * Validate if the establishment is connected to diffuser type account
     *
     * @param establishmentId
     * @param type
     * @param successCallback
     */
    check(establishmentId: number, type: string, successCallback: (isConnected: boolean) => void) {
      if (establishmentId && type) {
        const check: ICheckConnectDiffuser = {
          establishment_id: establishmentId,
          diffuser_type: type,
        }

        const authStore = useAuthenticationStore()

        DiffuserDataService.checkConnexionStatus(check, authStore.currentHeaders).then(
          (res) => {
            const isConnected = res.data.data.isConnected
            successCallback(isConnected)
          },
          (e) => {
            const alertStore = useAlertStore()
            switch (e.response.status) {
              case 401:
                break
              case 400:
                alertStore.push(AlertType.error, i18n.global.t("common.errors.default"), e.message)
                break
              case 409:
                alertStore.push(AlertType.error, i18n.global.t("common.errors.hubo_server_error"), e.message)
                break
              case 503:
                alertStore.push(AlertType.error, i18n.global.t("common.errors.uberall_server_error"), e.message)
                break
              default:
                alertStore.push(AlertType.error, i18n.global.t("common.errors.default"), e.message)
                break
            }
          }
        )
      }
    },

    disconnect(establishmentId: number, diffuserType: string, callback: () => void) {
      const authStore = useAuthenticationStore()
      DiffuserDataService.disconnect(
        { establishment_id: establishmentId, diffuser_type: diffuserType },
        authStore.currentHeaders
      ).then(
        (res) => {
          callback()
        },
        (e) => {
          const alertStore = useAlertStore()
          switch (e.response.status) {
            case 401:
              break
            case 503:
              alertStore.push(AlertType.error, i18n.global.t("common.errors.uberall_server_error"), e.message)
              break
            default:
              alertStore.push(AlertType.error, i18n.global.t("common.errors.default"), e.message)
              break
          }
        }
      )
    },
  },
})
