import type { RouteRecordRaw } from "vue-router"
import auth from "@/middlewares/auth"

const solicitationRoutes: RouteRecordRaw[] = [
  {
    path: "/solicitations",
    name: "solicitations",
    component: () => import("@/views/solicitations/SolicitationsManagement.vue"),
    meta: {
      middleware: [auth],
      title: "solicitations.page_title",
    },
  },
  {
    path: "/solicitations/maj/:token",
    name: "maj-form",
    component: () => import("@/views/solicitations/CmajForm.vue"),
    meta: {
      middleware: [auth],
      title: "Compléter mes informations | Uska",
    },
  },
  {
    path: "/solicitations/adh/:token",
    name: "adh-form",
    component: () => import("@/views/solicitations/CadhForm.vue"),
    meta: {
      middleware: [auth],
      title: "Voir les offres | Uska",
    },
  },
]

export default solicitationRoutes
