import type { RouteRecordRaw } from "vue-router"

const errorRoutes: RouteRecordRaw[] = [
  {
    path: "/error",
    name: "error",
    component: () => import("@/views/Error.vue"),
  },
  {
    path: "/error/version",
    name: "error-version",
    component: () => import("@/views/errors/Error.vue"),
  },
]

export default errorRoutes
