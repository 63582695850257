import http from "@/http-common"
import type {
  ICreateEstablishment,
  IEstablishmentContact,
  IEstablishmentGeneral,
  IEstablishmentHours,
  IEstablishmentSpecific,
} from "@/types/Establishment.types"

class EstablishmentDataService {
  /**
   * Create new establishment
   * @param establishment
   * @param headers
   * @returns
   */
  createEstablishment(establishment: ICreateEstablishment, headers: {}): Promise<any> {
    return http.post(`auth/establishments/create`, establishment, {
      headers: headers,
    })
  }

  checkIfEstablishmentExists(ids: Number[], headers: {}): Promise<any> {
    return http.post(
      `auth/establishments/find-duplication`,
      {
        provider_ids: ids,
      },
      {
        headers: headers,
      }
    )
  }

  /**
   * Set the establishment is_updatable property
   * Define if the establishment is ready to be sync (enable/disable the sync button)
   * @param establishmentId
   * @param updatable
   * @param headers
   * @returns
   */
  updateEstablishmentUpdatable(establishmentId: number, updatable: boolean, headers: {}): Promise<void> {
    return http.post(
      `auth/establishments/updatable-status/update`,
      {
        establishment_id: establishmentId,
        is_updatable: updatable,
      },
      {
        headers: headers,
      }
    )
  }

  /**
   * Update the general and base
   * @param establishment
   * @param headers
   * @returns
   */
  updateEstablishment(establishment: IEstablishmentGeneral, headers: {}): Promise<any> {
    return http.post(`auth/establishments/update`, establishment, {
      headers: headers,
    })
  }

  /**
   * Updates establishment's hours
   * @returns
   * @param payload
   */
  updateEstablishmentHours(payload: IEstablishmentHours): Promise<void> {
    return http.post(`/auth/establishments/opening-hours/update`, payload)
  }

  deleteEstablishmentPlannedPeriod(periodId: number): Promise<void> {
    return http.delete(`/auth/establishments/opening-period/delete/${periodId}`)
  }

  /**
   * Updates establishment's contact tab
   * @returns
   * @param payload
   */
  updateContact(payload: IEstablishmentContact): Promise<void> {
    return http.post(`/auth/establishments/communications/update`, payload)
  }

  /**
   * Updates establishment's specific tab
   * @returns
   * @param payload
   */
  updateSpecific(payload: IEstablishmentSpecific): Promise<void> {
    return http.post(`/auth/establishments/specific/update`, payload)
  }

  /**
   * Set the first plateforme connexion success date
   * @param establishmentId
   * @returns
   */
  setFirstConnexionDate(establishmentId: number): Promise<void> {
    return http.get(`/auth/establishments/set-first-connection/${establishmentId}`)
  }

  /**
   * Get the establishment
   * @param id
   * @returns
   * @param payload
   */
  getEstablishmentById(id: number, headers: {}): Promise<void> {
    return http.get(`/auth/establishments/${id}`, { headers: headers })
  }

  /**
   * Get the attributes for the establishment's category
   * @param id
   * @returns
   * @param payload
   */
  getAttributes(categoryId: number): Promise<void> {
    return http.get(`/auth/establishments/attributes/${categoryId}`)
  }

  /**
   * Get the dashboard welcome checklist status
   * @param establishmentId
   * @returns
   */
  getChecklist(establishmentId: number): Promise<void> {
    return http.get(`/auth/users/status/${establishmentId}`)
  }

  updateLnInscriptionChecklist(): Promise<void> {
    return http.get(`/auth/establishments/set-subscription-ln`)
  }

  /**
   * Update the viewsSolicitation property (user viewed for the first time their solicitations)
   * @param establishmentId
   * @returns
   */
  updateViewedSolicitations(establishmentId: number): Promise<void> {
    return http.get(`/auth/users/seen-solicitations/${establishmentId}`)
  }
}

export default new EstablishmentDataService()
