// Vue
import { i18n } from "@/i18n"

// Types
import type {
  IEstablishmentGeneral,
  IEstablishmentContact,
  IEstablishmentHours,
  IAttribute,
  IEstablishmentSpecific,
  ILanguage,
  IPayment,
  IEstablishment,
  IPeriodHours,
} from "@/types/Establishment.types"
import type { ICategory } from "@/types/Establishment.types"
import type { IMediaAlbumRules, MediaAlbum, MediaPayload } from "@/types/Media.types"

// Stores
import { defineStore } from "pinia"
import { useAccountStore } from "./account"
import { useAuthenticationStore } from "./authentication"
import { useAlertStore } from "./alert"

// Data
import { AlertType } from "@/static/Alert"

// Services
import EstablishmentDataService from "@/services/EstablishmentDataService"
import TranslationDataService from "@/services/TranslationDataService"
import MediaDataService from "@/services/MediaDataService"
import { type CommunicationCode, freemiumPlans } from "@/static/Establishment"
import UberallDataService from "@/services/UberallDataService"
import { useLocalDiffuserStore } from "./localDiffuser"

export const useEstablishmentStore = defineStore("establishment", {
  state: () => ({
    count: 0,

    /**
     * is the save bar shown ?
     * @type {boolean}
     */
    showSaveButton: false,

    /**
     * current form active tab index
     * @type {number}
     */
    currentTabIndex: 0,

    /**
     * form max index (number of tabs -1 cause index begin at 0)
     * @type {number}
     */
    maxIndex: 5,

    /**
     * form max index (number of tabs -1 cause index begin at 0)
     * @type {number}
     */
    configMaxIndex: 1,

    /**
     * is the current establishment form is in config mode ?
     * @type {boolean}
     */
    isConfigurationForm: false,

    /**
     * current selected establishment from endpoint (same for configuration and app form)
     * @type {IEstablishment}
     */
    currentEstablishment: {} as IEstablishment,

    /**
     * current selected establishment local updatable property
     * have this property locally prevent multiple request to database to get full establishment
     */
    currentEstablishmentIsUpdatable: true,

    /**
     * current selected establishment in form tabs objects
     * @type {object}
     */
    form: {
      general: {} as IEstablishmentGeneral,
      contacts: {} as IEstablishmentContact,
      platforms: {} as IEstablishmentContact,
      hours: {
        open_24_7: false,
        days: [],
        exceptional_periods: [],
      } as IEstablishmentHours,
      specific: {} as IEstablishmentSpecific,
    },

    /**
     * list of available categories
     * @type {ICategory[]}
     */
    categories: [] as ICategory[],

    /**
     * list of all attributes
     * @type {IAttribute[]}
     */
    attributes: [] as IAttribute[],

    /**
     * list of available attributes (depends on current establishment main category)
     * @type {IAttribute[]}
     */
    availableAttributes: [] as IAttribute[],

    /**
     * list of all payment options
     * @type {IPayment[]}
     */
    paymentOptions: [] as IPayment[],

    /**
     * list of available languages
     * @type {ILanguage[]}
     */
    languages: [] as ILanguage[],

    /**
     * list of available albums for selected main category
     * @type {IMediaAlbumRules[]}
     */
    albums: [] as IMediaAlbumRules[],

    /**
     * is the current form tab loading ?
     * @type {boolean}
     */
    isLoading: false,

    /**
     * is the form in syncing ?
     * @type {boolean}
     */
    inSyncing: false,

    /**
     * is the current establishment loading ?
     * @type {boolean}
     */
    formLoaded: false,
  }),

  getters: {
    isCurrentEstablishmentActiv: (state): {} => {
      return state.currentEstablishment.validated && state.currentEstablishment.contracts.validated
    },
  },

  actions: {
    /**
     * Clear all establishment data
     */
    clear() {
      this.showSaveButton = false
      this.isConfigurationForm = false
      this.currentEstablishment = {} as IEstablishment
      this.form = {
        general: {} as IEstablishmentGeneral,
        contacts: {} as IEstablishmentContact,
        platforms: {} as IEstablishmentContact,
        hours: {
          open_24_7: false,
          days: [],
          exceptional_periods: [],
        } as IEstablishmentHours,
        specific: {} as IEstablishmentSpecific,
      }
      this.isLoading = false
      this.formLoaded = false
    },

    changeCurrentEstablishment(newEstablishment: IEstablishment) {
      this.initEstablishment(newEstablishment.id.toString(), () => {
        const accountStore = useAccountStore()
        accountStore.reload += 1
      })
    },

    getIcon(establishment: IEstablishment) {
      if (establishment && establishment.name) {
        const baseUrl = "https://api.dicebear.com/7.x/initials/svg?"
        const size = "size=32"
        // const radius = "&radius=50"
        const font = "&fontFamily=Trebuchet%20MS&fontSize=61&fontWeight=600"

        const unique = establishment.id + establishment.name.length

        return baseUrl + size + font + "&seed=" + establishment.name + establishment.id + unique
      } else {
        return null
      }
    },

    /**
     * Get the current authenticated account establishments list
     * In configuration, user should have only one establishment, so we take only the first one
     * The request is called only the first time, not at all changes
     * @param id
     * @param successCallback
     */
    initEstablishment(id?: string, successCallback?: () => void) {
      if (id) {
        this.getCurrentEstablishment(Number(id), () => {
          this.formLoaded = true
          if (successCallback) {
            successCallback()
          }
        })
      } else {
        const account = useAccountStore()
        return account.getEstablishments((establishments) => {
          if (establishments[0]) {
            this.currentEstablishment = establishments[0]
            this.currentEstablishmentIsUpdatable = this.currentEstablishment.is_updatable

            this.isCurrentFreemium()

            this.formLoaded = true
            if (successCallback) {
              successCallback()
            }
          } else {
            const alertStore = useAlertStore()
            alertStore.push(AlertType.error, i18n.global.t("common.errors.no_establishment"), "initEstablishment")
          }
        })
      }
    },

    /**
     * Get all establishment data by id
     * @param id
     * @param successCallback
     */
    getCurrentEstablishment(id?: number, successCallback?: () => void) {
      let establishmentId = id ?? this.currentEstablishment.id

      const auth = useAuthenticationStore()
      if (establishmentId) {
        EstablishmentDataService.getEstablishmentById(establishmentId, auth.currentHeaders).then(
          (res) => {
            if (res.data.data) {
              this.currentEstablishment = JSON.parse(JSON.stringify(res.data.data))
              this.currentEstablishmentIsUpdatable = this.currentEstablishment.is_updatable

              this.isCurrentFreemium()

              if (successCallback) {
                successCallback()
              }
            } else {
              const alertStore = useAlertStore()
              alertStore.push(
                AlertType.error,
                i18n.global.t("common.errors.no_establishment"),
                "getCurrentEstablishment"
              )
            }
          },
          (e) => {
            const alertStore = useAlertStore()

            switch (e.response.status) {
              case 401:
                break
              default:
                alertStore.push(AlertType.error, i18n.global.t("common.errors.default"), e.message)
                break
            }
          }
        )
      } else {
        const alertStore = useAlertStore()
        this.router.push({ name: "auth" })
        auth.logout()
        alertStore.push(AlertType.info, "Votre session a expirée")
        console.error("Analyse - Pas d'établissement trouvé")
      }
    },

    isCurrentFreemium() {
      const accountStore = useAccountStore()

      accountStore.isFreemium = freemiumPlans.includes(this.currentEstablishment.product_plan?.code)

      const hasAtLeastOneLocalDiffuser =
        accountStore.establishments.map((establishment) => establishment.local_diffusers.length).length > 0

      if (accountStore.establishments.length > 0 && hasAtLeastOneLocalDiffuser && accountStore.isFreemium) {
        const localDiffuserStore = useLocalDiffuserStore()
        localDiffuserStore.logo()
      }
    },

    /**
     * Refresh local establishment data
     */
    async refreshCurrentEstablishment(callback?: () => void) {
      const establishmentId = this.currentEstablishment.id

      this.isLoading = true

      this.getCurrentEstablishment(establishmentId, callback)
    },

    /**
     * Synchronize all establishment data in all diffusers list
     * The data is sent to uberall for sync, but the data will not be in the diffuser list right now
     * It can be longer, it can be stop by a problem... Go to listing view to now the diffusers status sync
     * @param establishmentId
     * @param successCallback
     * @param errorCallback
     */
    sync(establishmentId: number, successCallback?: () => void, errorCallback?: () => void) {
      this.inSyncing = true

      const authStore = useAuthenticationStore()

      UberallDataService.syncLocation(establishmentId, authStore.currentHeaders).then(
        () => {
          this.inSyncing = false
          if (successCallback) {
            successCallback()
          }
        },
        (e) => {
          const alertStore = useAlertStore()
          switch (e.response.status) {
            case 401:
              break
            default:
              alertStore.push(AlertType.error, i18n.global.t("common.errors.default"), e.message)
              break
          }
          this.inSyncing = false
          if (errorCallback) {
            errorCallback()
          }
        }
      )
    },

    /**
     * Update the is_updatable property to know if the current establishment is ready for sync
     * @param isUpdatable
     */
    updatable(isUpdatable: boolean, successCallback?: () => void) {
      const authStore = useAuthenticationStore()

      EstablishmentDataService.updateEstablishmentUpdatable(
        this.currentEstablishment.id,
        isUpdatable,
        authStore.currentHeaders
      ).then(
        (res) => {
          this.currentEstablishmentIsUpdatable = isUpdatable
          if (successCallback) {
            setTimeout(() => {
              successCallback()
            }, 400)
          }
        },
        (e) => {
          const alertStore = useAlertStore()
          switch (e.response.status) {
            case 401:
              break
            default:
              alertStore.push(AlertType.error, i18n.global.t("common.errors.default"), e.message)
              break
          }
        }
      )
    },

    setFirstConnexion() {
      if (this.currentEstablishment.id) {
        EstablishmentDataService.setFirstConnexionDate(this.currentEstablishment.id).then(
          (res) => {
            //
          },
          (e) => {
            const alertStore = useAlertStore()
            switch (e.response.status) {
              case 401:
                break
              default:
                alertStore.push(AlertType.error, i18n.global.t("common.errors.default"), e.message)
                break
            }
          }
        )
      }
    },

    // ========= GENERAL =========

    /**
     * Save all general tab form (in uberall and uska)
     * @param showSuccess show alert if request is successfully complete
     */
    saveGeneral(showSuccess: boolean, callback: () => void) {
      this.isLoading = true

      if (!this.form.general.long_description) {
        this.form.general.long_description = ""
      }

      if (!this.form.general.short_description) {
        this.form.general.short_description = ""
      }

      const authStore = useAuthenticationStore()
      const alertStore = useAlertStore()

      EstablishmentDataService.updateEstablishment(this.form.general, authStore.currentHeaders).then(
        () => {
          this.updatable(true, callback)
          this.currentEstablishment.name = this.form.general.name

          if (showSuccess) {
            alertStore.push(
              AlertType.success,
              i18n.global.t("establishments/formulaire.generic_informations.success_alert_message"),
              i18n.global.t("establishments/formulaire.generic_informations.tab_title")
            )
          }
        },
        (e) => {
          this.isLoading = false
          switch (e.response.status) {
            case 401:
              break
            default:
              alertStore.push(AlertType.error, i18n.global.t("common.errors.default"), e.message)
              break
          }
        }
      )
    },

    loadCategories(successCallback?: () => void) {
      let locale = i18n.global.locale.value
      if (locale === undefined) {
        locale = import.meta.env.VITE_APP_I18N_LOCALE
      }

      TranslationDataService.fetchCategories().then(
        (response) => {
          if (response.data[locale]) {
            this.categories = response.data[locale]
            if (successCallback) {
              successCallback()
            }
          }
        },
        (e) => {
          const authStore = useAuthenticationStore()
          const alertStore = useAlertStore()
          switch (e.response.status) {
            case 401:
              break
            default:
              alertStore.push(AlertType.error, i18n.global.t("common.errors.default"), e.message)
              break
          }
        }
      )
    },

    // ========= SPECIFIC =========

    /**
     * Save all specific tab form (in uberall and uska)
     * @param showSuccess show alert if request is successfully complete
     */
    saveSpecific(showSuccess: boolean, callback: () => void) {
      this.isLoading = true

      const alertStore = useAlertStore()

      EstablishmentDataService.updateSpecific(this.form.specific).then(
        () => {
          this.updatable(true, callback)

          if (showSuccess) {
            alertStore.push(
              AlertType.success,
              i18n.global.t("establishments/formulaire.specific_informations.success_alert_message"),
              i18n.global.t("establishments/formulaire.specific_informations.tab_title")
            )
          }
        },
        (e) => {
          this.isLoading = false
          const authStore = useAuthenticationStore()
          switch (e.response.status) {
            case 401:
              break
            default:
              alertStore.push(AlertType.error, i18n.global.t("common.errors.default"), e.message)
              break
          }
        }
      )
    },

    loadAttributes() {
      let locale = i18n.global.locale.value
      if (locale === undefined) {
        locale = import.meta.env.VITE_APP_I18N_LOCALE
      }

      TranslationDataService.fetchAttributes().then(
        (response) => {
          if (response.data[locale]) {
            this.attributes = response.data[locale]
          }
        },
        (e) => {
          const alertStore = useAlertStore()

          switch (e.response.status) {
            case 401:
              break
            default:
              alertStore.push(AlertType.error, i18n.global.t("common.errors.default"), e.message)
              break
          }
        }
      )
    },

    loadLanguages() {
      let locale = i18n.global.locale.value
      if (locale === undefined) {
        locale = import.meta.env.VITE_APP_I18N_LOCALE
      }

      TranslationDataService.fetchLanguages().then(
        (response) => {
          if (response.data[locale]) {
            this.languages = response.data[locale]
          }
        },
        (e) => {
          const authStore = useAuthenticationStore()
          const alertStore = useAlertStore()

          switch (e.response.status) {
            case 401:
              break
            default:
              alertStore.push(AlertType.error, i18n.global.t("common.errors.default"), e.message)
              break
          }
        }
      )
    },

    loadPaymentOptions() {
      let locale = i18n.global.locale.value
      if (locale === undefined) {
        locale = import.meta.env.VITE_APP_I18N_LOCALE
      }

      TranslationDataService.fetchPaymentOptions().then(
        (response) => {
          if (response.data[locale]) {
            this.paymentOptions = response.data[locale]
          }
        },
        (e) => {
          const authStore = useAuthenticationStore()
          const alertStore = useAlertStore()

          switch (e.response.status) {
            case 401:
              break
            default:
              alertStore.push(AlertType.error, i18n.global.t("common.errors.default"), e.message)
              break
          }
        }
      )
    },

    loadAvailableAttributes(categoryId: number) {
      const alertStore = useAlertStore()

      EstablishmentDataService.getAttributes(categoryId).then(
        (res) => {
          if (res.data.success) {
            this.availableAttributes = res.data.data
          } else {
            this.availableAttributes = []
            alertStore.push(
              AlertType.error,
              i18n.global.t("common.errors.default"),
              i18n.global.t("establishments/formulaire.specific_informations.attributes.label")
            )
          }
        },
        (e) => {
          const authStore = useAuthenticationStore()
          switch (e.response.status) {
            case 401:
              break
            default:
              alertStore.push(AlertType.error, i18n.global.t("common.errors.default"), e.message)
              break
          }
        }
      )
    },

    // ========= CONTACT & PLATEFORMS =========

    /**
     * Save all communications (platforms tab and contact tab) (in uberall and uska)
     * @param showSuccess show alert if request is successfully complete
     */
    saveCommunications(showSuccess: boolean, callback: () => void) {
      this.isLoading = true

      const alertStore = useAlertStore()

      EstablishmentDataService.updateContact(this.form.contacts).then(
        () => {
          this.updatable(true, callback)

          if (showSuccess) {
            alertStore.push(AlertType.success, i18n.global.t("establishments/formulaire.contact.success_alert_message"))
          }
        },
        (e) => {
          this.isLoading = false
          const authStore = useAuthenticationStore()
          switch (e.response.status) {
            case 401:
              break
            default:
              alertStore.push(AlertType.error, i18n.global.t("common.errors.default"), e.message)
              break
          }
        }
      )
    },

    updateCom(event: { target: { value: any } }, code: CommunicationCode) {
      const val = event.target.value
      const communication = this.form.contacts.communications.find((com) => com.code === code)

      if (communication !== undefined) {
        communication.value = val
      } else {
        this.form.contacts.communications.push({
          code: code,
          value: val,
        })
      }
    },

    setCommunication(value: string, code: CommunicationCode) {
      const communication = this.form.contacts.communications.find((com) => com.code === code)

      if (communication !== undefined) {
        communication.value = value
      } else {
        this.form.contacts.communications.push({
          code: code,
          value: value,
        })
      }
    },

    getCommunicationValueFromCode(code: CommunicationCode) {
      const communications = this.currentEstablishment.communications
      let value = null

      if (communications) {
        const com = communications.find((_com) => _com.code === code)

        if (com) {
          value = com.value
        }
      }

      return value
    },

    // ========= HOURS =========

    /**
     * Save all hours tab form (in uberall and uska)
     * @param showSuccess show alert if request is successfully complete
     */
    saveHours(showSuccess: boolean, removedPeriods: IPeriodHours[], callback: () => void) {
      this.isLoading = true

      const alertStore = useAlertStore()

      if (this.currentEstablishment) {
        // remove periods
        const promises: Promise<any>[] = []

        removedPeriods.forEach((period) => {
          const promise = EstablishmentDataService.deleteEstablishmentPlannedPeriod(period.id!)
          promises.push(promise)
        })

        Promise.all(promises).then(() => {
          setTimeout(async () => {
            // save periods
            EstablishmentDataService.updateEstablishmentHours({
              ...this.form.hours,
              establishment_id: this.currentEstablishment.id,
            }).then(
              () => {
                this.updatable(true, callback)

                if (showSuccess) {
                  alertStore.push(
                    AlertType.success,
                    i18n.global.t("establishments/formulaire.hours.success_alert_message"),
                    i18n.global.t("establishments/formulaire.hours.tab_title")
                  )
                }
              },
              (e) => {
                this.isLoading = false
                switch (e.response.status) {
                  default:
                    alertStore.push(AlertType.error, i18n.global.t("common.errors.default"), e.message)
                    break
                }
              }
            )
          }, 1000)
        })
      }
    },

    // ========= MEDIAS =========

    loadAlbums() {
      if (this.currentEstablishment.category_id) {
        MediaDataService.getCategoryAlbumsRules(this.currentEstablishment.category_id).then(
          (res) => {
            this.albums = res.data.data
          },
          (e) => {
            const alertStore = useAlertStore()

            switch (e.response.status) {
              case 401:
                break
              default:
                alertStore.push(AlertType.error, i18n.global.t("common.errors.default"), e.message)
                break
            }
          }
        )
      }
    },

    /**
     * Upload new media for current establishment
     * @param album current selected album to upload new media
     * @param submitted file to upload
     */
    uploadMedias(album: MediaAlbum, submitted: File, callback?: () => void): Promise<any> {
      const alertStore = useAlertStore()
      if (this.currentEstablishment) {
        const payload: MediaPayload = {
          establishment_id: this.currentEstablishment!.id,
          album: album.code,
          file: submitted,
        }

        const authStore = useAuthenticationStore()

        const promise = MediaDataService.uploadMedia(payload, authStore.currentHeaders).then(
          () => {
            this.updatable(true, () => {
              this.getCurrentEstablishment()
            })

            if (callback) {
              callback()
            }
          },
          (e) => {
            switch (e.response.status) {
              case 401:
                break
              default:
                alertStore.push(AlertType.error, i18n.global.t("common.errors.default"), e.message)
                break
            }
          }
        )

        return promise
      } else {
        return new Promise(() => {
          alertStore.push(AlertType.error, i18n.global.t("common.errors.no_establishment"))
        })
      }
    },

    setMediaRank(id: number, successCallback?: () => void) {
      MediaDataService.updateMediaRank(id).then(
        async () => {
          this.updatable(true, () => {
            if (successCallback) {
              successCallback()
            }
          })
        },
        (e) => {
          const alertStore = useAlertStore()

          switch (e.response.status) {
            case 401:
              break
            default:
              alertStore.push(AlertType.error, i18n.global.t("common.errors.default"), e.message)
              break
          }
        }
      )
    },

    deleteMedia(id: number, successCallback?: () => void) {
      MediaDataService.deleteMedia(id).then(
        async () => {
          this.updatable(true, () => {
            if (successCallback) {
              successCallback()
            }
          })
        },
        (e) => {
          const alertStore = useAlertStore()

          switch (e.response.status) {
            case 401:
              break
            default:
              alertStore.push(AlertType.error, i18n.global.t("common.errors.default"), e.message)
              break
          }
        }
      )
    },
  },

  persist: {
    enabled: true,
  },
})
