import type { RouteRecordRaw } from "vue-router"
import auth from "@/middlewares/auth"

const professionalSpaceRoutes: RouteRecordRaw[] = [
  {
    path: "/professional-space/:contactToken/webform/:webformToken?",
    name: "professional-space-open-form",
    component: () => import("@/views/professional-space/InformationsOpenForm.vue"),
    meta: {
      hideIntercom: true,
    },
  },
  {
    path: "/professional-space/inscription",
    name: "professional-space-inscription",
    component: () => import("@/views/professional-space/Inscription.vue"),
    meta: {
      hideIntercom: true,
    },
  },
  {
    path: "/professional-space/authentification",
    name: "professional-space-authentication",
    component: () => import("@/views/professional-space/Connexion.vue"),
    meta: {
      hideIntercom: true,
    },
  },
]

export default professionalSpaceRoutes
