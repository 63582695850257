import type { RouteRecordRaw } from "vue-router"
import auth from "@/middlewares/auth"

const googleRoutes: RouteRecordRaw[] = [
  {
    path: "/google-connexion/select",
    name: "gg-connect-select",
    component: () => import("@/views/google/SelectLocation.vue"),
    meta: {
      middleware: [auth],
      title: "Se connecter à Google | Uska",
    },
  },
  {
    path: "/google-connexion/location-connected",
    name: "gg-location-connected",
    component: () => import("@/views/google/LocationConnected.vue"),
    meta: {
      middleware: [auth],
      title: "Se connecter à Google | Uska",
    },
  },
]

export default googleRoutes
