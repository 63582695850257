import type { RouteRecordRaw } from "vue-router"
import auth from "@/middlewares/auth"

const freemiumRoutes: RouteRecordRaw[] = [
  {
    path: "/analyse",
    name: "analyse",
    component: () => import("@/views/analyse/AnalyseManagement.vue"),
    meta: {
      middleware: [auth],
      title: "analyse.page_title",
    },
  },
  {
    path: "/freemium/convert",
    name: "freemium-convert",
    component: () => import("@/views/freemium/FreemiumConvert.vue"),
    meta: {
      middleware: [auth],
      title: "freemium/convert.page_title",
    },
  },
  {
    path: "/freemium/comparison",
    name: "freemium-comparison",
    component: () => import("@/views/freemium/PremiumComparison.vue"),
    meta: {
      middleware: [auth],
      title: "freemium/comparison.page_title",
    },
  },

  {
    path: "/freemium/contact",
    name: "freemium-contact",
    component: () => import("@/views/freemium/Contact.vue"),
    meta: {
      middleware: [auth],
      title: "freemium/contact.page_title",
    },
  },
  {
    path: "/freemium/contact-convert",
    name: "freemium-contact-convert",
    component: () => import("@/views/freemium/ContactForPremium.vue"),
    meta: {
      middleware: [auth],
      title: "freemium/contact-convert.page_title",
    },
  },
  {
    path: "/freemium/meeting",
    name: "freemium-meeting",
    component: () => import("@/views/freemium/HubspotMeeting.vue"),
    meta: {
      middleware: [auth],
      title: "freemium/meeting.page_title",
    },
  },
]

export default freemiumRoutes
