import type { RouteRecordRaw } from "vue-router"
import auth from "@/middlewares/auth"
import information from "@/middlewares/information"

const establishmentRoutes: RouteRecordRaw[] = [
  {
    path: "/establishments",
    name: "establishment-management",
    component: () => import("@/views/establishments/EstablishmentsManagement.vue"),
    meta: {
      middleware: [auth],
    },
  },
  {
    path: "/establishment/new",
    name: "new-establishment",
    component: () => import("@/views/establishments/NewEstablishment.vue"),
    meta: {
      middleware: [auth],
      title: "establishment/new.page_title",
    },
  },
  {
    path: "/establishment/new/form",
    name: "new-establishment-premium",
    component: () => import("@/views/establishments/NewEstablishmentPremium.vue"),
    meta: {
      middleware: [auth],
      title: "establishment/new.page_title",
    },
  },
  {
    path: "/establishment",
    name: "establishment",
    component: () => import("@/views/establishments/EstablishmentFormView.vue"),
    meta: {
      middleware: [auth, information],
      title: "establishments/formulaire.page_title",
    },
  },
  {
    path: "/establishments/listing/:diffuserType?",
    name: "establishment-listing",
    component: () => import("@/views/establishments/DiffusersListing.vue"),
    meta: {
      middleware: [auth],
      title: "establishments/listing.page_title",
    },
  },
]

export default establishmentRoutes
