import type { RouteRecordRaw } from "vue-router"
import auth from "@/middlewares/auth"

const configurationRoutes: RouteRecordRaw[] = [
  {
    path: "/welcome",
    name: "config-welcome",
    component: () => import("@/views/configuration/Welcome.vue"),
    meta: {
      middleware: [auth],
    },
  },
  {
    path: "/configuration/contact",
    name: "config-contact",
    component: () => import("@/views/configuration/Contact.vue"),
    meta: {
      middleware: [auth],
    },
  },
  {
    path: "/configuration/begin",
    name: "config-begin",
    component: () => import("@/views/configuration/Begin.vue"),
    meta: {
      middleware: [auth],
    },
  },
  {
    path: "/configuration/connect/:diffuserType?",
    name: "config-connect",
    component: () => import("@/views/configuration/Connexion.vue"),
    meta: {
      middleware: [auth],
    },
  },
  {
    path: "/configuration/establishment",
    name: "config-establishment",
    component: () => import("@/views/configuration/Establishment.vue"),
    meta: {
      middleware: [auth],
    },
  },
  {
    path: "/configuration/listing",
    name: "config-listing",
    component: () => import("@/views/configuration/Listing.vue"),
    meta: {
      middleware: [auth],
    },
  },

  {
    path: "/welcome/free",
    name: "config-freemium",
    component: () => import("@/views/configuration/freemium/Establishments.vue"),
    meta: {
      middleware: [auth],
    },
  },
  {
    path: "/welcome/free/introduction",
    name: "config-freemium-introduction",
    component: () => import("@/views/configuration/freemium/Introduction.vue"),
    meta: {
      middleware: [auth],
    },
  },
]

export default configurationRoutes
