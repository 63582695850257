// Vue
import { i18n } from "@/i18n"

import type { IFeature } from "@/types/Users.types"
import { defineStore } from "pinia"
import type { IEstablishment } from "@/types/Establishment.types"

import AccountDataService from "@/services/AccountDataService"
import { useAuthenticationStore } from "./authentication"
import { useAlertStore } from "./alert"
import { useEstablishmentStore } from "./establishment"
import { AlertType } from "@/static/Alert"
import { ConfigurationState } from "@/static/Configuration"
import type { IAccountForm, IGoogleButton, IPlan } from "@/types/Account.types"
import SubscriptionDataService from "@/services/SubscriptionDataService"
import type IContactForm from "@/types/Contact.types"
import OnboardingDataService from "@/services/OnboardingDataService"
import type { IMenuItem } from "@/types/Menu.types"
import GoogleDataService from "@/services/GoogleDataService"
import { useTrackingStore } from "./tracking"
import { FreemiumTrafficCode } from "@/static/Tracking"
import { freemiumPlans } from "@/static/Establishment"

export const useAccountStore = defineStore("account", {
  state: () => ({
    reload: 0,
    establishments: [] as IEstablishment[],
    features: [] as IFeature[],

    /** @type {Boolean | null} current authenticated user first attempt to the app */
    firstAttempt: localStorage.getItem("firstAttempt") as string | null,

    productPlans: [] as IPlan[],

    /**
     * Is the current establishment freemium ?
     */
    isFreemium: false,

    /**
     * Is the current establishment freemium but for more than 3 months, so it's disable ?
     */
    isFreemiumButEndingPlan: false,

    /**
     * Is the current account has at least one freemium establishment ?
     */
    hasFreemium: false,

    /**
     * Is the current account has at least one premium establishment ?
     */
    hasPremium: false,

    /**
     * Is the current account has only freemium establishment(s) ?
     */
    onlyFreemium: true,

    isLoading: true,
    establishmentLoaded: false,
    hasLoadedMenu: false,

    menuItems: [] as IMenuItem[],

    googleAuthUrl: null,
  }),

  getters: {
    isFirstAttempt: (state): {} => {
      return state.firstAttempt === "true" || state.firstAttempt === null
    },
  },

  actions: {
    clear() {
      this.establishments = [] as IEstablishment[]
      this.features = [] as IFeature[]
      this.firstAttempt = localStorage.getItem("firstAttempt") as string | null
      this.productPlans = [] as IPlan[]
      this.isFreemium = false
      this.isLoading = true
    },

    setFirstAttempt(bool: string) {
      localStorage.setItem("firstAttempt", bool)
      this.firstAttempt = bool
    },

    /**
     * Create new account
     * @param {IAccountForm} form
     * @param contactToken
     * @param huboAccountId
     * @param successCallback
     */
    create(
      form: IAccountForm,
      contactToken: string,
      huboAccountId: number,
      successCallback: (authData: { access_token: string; token_type: string }, accountId: number) => void,
      errorCallback?: () => void
    ) {
      const authStore = useAuthenticationStore()

      AccountDataService.createAccount(form, contactToken, huboAccountId).then(
        (res) => {
          authStore.email = form.email
          const accountId = res.data.data.id

          // If contactToken exists, start a freemium onboarding process
          if (contactToken) {
            this.updateState(ConfigurationState.START_FREEMIUM, accountId)
          }
          // If tracking not exists, start configuration at the onboarding
          else {
            this.updateState(ConfigurationState.START_ONBOARDING, accountId)
          }

          // The first time the user of new account login, show the welcome page
          this.setFirstAttempt("true")

          const alertStore = useAlertStore()
          alertStore.push(AlertType.success, i18n.global.t("common.messages.account_created_successfully"))

          successCallback(res.data.auth_data as { access_token: string; token_type: string }, accountId)
        },
        (e) => {
          const alertStore = useAlertStore()
          switch (e.response.status) {
            case 401:
              break
            case 422:
              authStore.authenticationError(e.response.code, () => {
                alertStore.push(AlertType.error, i18n.global.t("common.errors.email_already_in_used"), e.message)
              })
              break
            default:
              alertStore.push(AlertType.error, i18n.global.t("common.errors.default"), e.message)
              break
          }
          if (errorCallback) {
            errorCallback()
          }
        }
      )
    },

    /**
     * Get Google button url for sign in or sign up
     * @param {AccountType} accountType
     * @param {GoogleAccountType} connectType
     * @param token
     * @param id
     * @param successCallback
     */
    googleButtonUrl(data: IGoogleButton, successCallback?: () => void) {
      GoogleDataService.getAuthenticationUrl(data).then(
        (res) => {
          this.googleAuthUrl = res.data.auth_url
          if (successCallback) {
            successCallback()
          }
        },
        (e) => {
          const authStore = useAuthenticationStore()
          const alertStore = useAlertStore()

          switch (e.response.status) {
            case 401:
              break
            case 422:
              authStore.authenticationError(e.response.code, () => {
                alertStore.push(AlertType.error, i18n.global.t("common.errors.email_already_in_used"), e.message)
              })
              break
            case 426:
              this.router.push({ name: "error-version" })
              alertStore.push(AlertType.info, "Merci de patienter encore quelques instants...")
              break
            default:
              alertStore.push(AlertType.error, i18n.global.t("common.errors.default"), e.message)
              break
          }
        }
      )
    },

    /**
     * Authenticate or create account with Google
     * If account existing but with a password, google authentication will be automatically created
     * @param code
     * @param state
     */
    googleAuth(code: string, state: string) {
      const authenticationStore = useAuthenticationStore()
      GoogleDataService.authenticateWithGoogle({
        code: code,
        state: state,
        email: authenticationStore.user ? authenticationStore.user.email : null,
      }).then(
        (res) => {
          const response = res.data
          this.setFirstAttempt("false")

          if (response.auth_data) {
            const authUser = response.auth_data as {
              access_token: string
              token_type: string
              expires_in: string
            }

            if (response.data) {
              // c'était une création (sign_up)
              const accountData = response.data as {
                hubo_account_id: string
                hubo_contact_token: string
                id: number
              }

              if (accountData.hubo_account_id && accountData.hubo_contact_token) {
                // Création de compte freemium

                // Ajouter un tracking
                const trackingStore = useTrackingStore()
                trackingStore.createFreemiumTraffic(FreemiumTrafficCode.FREEMIUM_ACCOUNT_CREATED, accountData.id)

                // Mettre à jour le statut de configuration
                this.updateState(ConfigurationState.START_FREEMIUM, accountData.id)
              } else {
                // Création de compte premium

                // Mettre à jour le statut de configuration
                this.updateState(ConfigurationState.START_ONBOARDING, accountData.id)
              }

              // The first time the user of new account login, show the welcome page
              this.setFirstAttempt("true")

              const alertStore = useAlertStore()
              alertStore.push(AlertType.success, i18n.global.t("common.messages.account_created_successfully"))
            }

            // LogIn l'utilisateur

            authenticationStore.storeLoginUser(authUser.access_token, authUser.token_type, this.firstAttempt === "true")
          } else {
            // return error
            const alertStore = useAlertStore()
            alertStore.push(AlertType.error, i18n.global.t("common.errors.default"))
            this.router.go(-2)
          }
        },
        (e) => {
          const alertStore = useAlertStore()

          switch (e.response.status) {
            case 401:
              break
            case 400:
              if (e.response.data.message === "CONTACT_TOKEN_ALREADY_USED") {
                // User tried to create account for a Hubo contact token existing (he has already an account with another email)
                alertStore.push(AlertType.info, i18n.global.t("common.errors.account_already_exist"))
                this.router.push({ name: "auth" })
              } else {
                // Bad request (erreur sur les paramètres données dans l'endpoint /auth-url)
                alertStore.push(AlertType.error, i18n.global.t("common.errors.default"), e.message)
                this.router.go(-2)
              }
              break
            case 404:
              // Only for the authentication
              alertStore.push(AlertType.error, i18n.global.t("common.errors.email_unknown"))
              this.router.push({ name: "auth" })
              break
            case 500:
              // 	The given code is not valid, google responded with an error OR error while the account creation.
              alertStore.push(AlertType.error, i18n.global.t("common.errors.default"), e.message)
              this.router.go(-2)
              break
            default:
              alertStore.push(AlertType.error, i18n.global.t("common.errors.default"), e.message)
              this.router.go(-2)
              break
          }
        }
      )
    },

    askGooglePermissions(code: string, state: string, redirect_url = null) {
      const authenticationStore = useAuthenticationStore()

      return GoogleDataService.authenticateWithGoogle({
        code: code,
        state: state,
        redirect_url: redirect_url,
        email: authenticationStore.user ? authenticationStore.user.email : null,
      })
    },

    /**
     * Get all current account subscriptions
     */
    getSubscriptions(successCallback?: () => void) {
      this.isLoading = true
      const alertStore = useAlertStore()
      const auth = useAuthenticationStore()

      SubscriptionDataService.getSubscriptions(auth.currentHeaders).then(
        (res) => {
          this.productPlans = res.data.data

          this.onlyFreemium =
            this.productPlans.find((productPlan) => !freemiumPlans.includes(productPlan.code)) === undefined

          this.isLoading = false

          if (successCallback) {
            successCallback()
          }
        },
        (e) => {
          this.isLoading = false
          switch (e.response.status) {
            case 401:
              break
            default:
              alertStore.push(AlertType.error, i18n.global.t("common.errors.default"), e.message)
              break
          }
        }
      )
    },

    /**
     * Get all authenticated account access features
     * @param callback
     */
    getFeatures(callback: (features: any) => void) {
      const authStore = useAuthenticationStore()

      AccountDataService.features(authStore.currentHeaders).then(
        (res) => {
          this.features = res.data.data
          callback(res.data.data)
        },
        (e) => {
          const alertStore = useAlertStore()
          switch (e.response.status) {
            case 401:
              break
            default:
              alertStore.push(AlertType.error, i18n.global.t("common.errors.default"), e.message)
              break
          }
        }
      )
    },

    /**
     * Get all authenticated account establishments
     * @param callback
     */
    getEstablishments(callback: (establishments: IEstablishment[]) => void, showError?: Boolean) {
      const authStore = useAuthenticationStore()

      if (showError === null || showError === undefined) {
        showError = true
      }

      if (authStore.currentHeaders.Authorization && authStore.user?.email) {
        const establishmentStore = useEstablishmentStore()
        establishmentStore.isLoading = true
        return AccountDataService.establishments(authStore.currentHeaders).then(
          (res) => {
            const etabs = [] as IEstablishment[]
            res.data.data.establishments.forEach((establishment: IEstablishment) => {
              if (establishment) {
                etabs.push(establishment)
              }
            })
            this.establishments = etabs
            this.hasFreemium = res.data.data.has_freemium
            this.hasPremium = res.data.data.has_premium

            const ids = etabs.map((etab) => etab.id)

            // cas du login as qui ne supprime pas le currentEstablishment (on se retrouve avec l'établissement d'un autre compte)
            if (
              establishmentStore.currentEstablishment === undefined ||
              (establishmentStore.currentEstablishment.id !== null &&
                !ids.includes(establishmentStore.currentEstablishment.id))
            ) {
              establishmentStore.currentEstablishment = etabs[0]
            }

            establishmentStore.isLoading = false

            callback(res.data.data.establishments)
          },
          (e) => {
            const alertStore = useAlertStore()
            switch (e.response.status) {
              case 401:
                break
              case 426:
                // Don't show errors
                break
              default:
                alertStore.push(AlertType.error, i18n.global.t("common.errors.default"), e.message)
                break
            }
          }
        )
      }
    },

    /**
     * Update the current authenticated account configuration state
     * @param state
     * @param accountId
     */
    updateState(state: ConfigurationState, accountId?: Number | null) {
      const currentAccountId = this._getAccountId(accountId)

      const authStore = useAuthenticationStore()
      const alertStore = useAlertStore()

      // If currentAccountId not find, don't update state
      if (currentAccountId) {
        AccountDataService.updateConfigurationState(currentAccountId, state).then(
          (res) => {
            if (res.data.success) {
              if (authStore.user && authStore.user!.account) {
                // Update current user account configuration state
                authStore.user!.account.configuration_state = state
              }
            } else {
              alertStore.push(AlertType.error, i18n.global.t("common.errors.default"), res.data.code)
            }
          },
          (e) => {
            switch (e.response.status) {
              case 401:
                break
              default:
                alertStore.push(AlertType.error, i18n.global.t("common.errors.default"), e.message)
                break
            }
          }
        )
      }
    },

    /**
     * At the end of configuration process, user account configured colonne will change to true
     * @param accountId
     */
    setAccountConfigured(configured: boolean, successCallback: () => void) {
      const currentAccountId = this._getAccountId()

      const alertStore = useAlertStore()

      // If currentAccountId not find, don't update state
      if (currentAccountId) {
        AccountDataService.setAccountConfigured(currentAccountId, configured).then(
          () => {
            successCallback()
          },
          (e) => {
            const authStore = useAuthenticationStore()

            switch (e.response.status) {
              case 401:
                break
              default:
                alertStore.push(AlertType.error, i18n.global.t("common.errors.default"), e.message)
                break
            }
          }
        )
      } else {
        alertStore.push(AlertType.error, i18n.global.t("common.errors.no_account_id"))
      }
    },

    /**
     * Get the current connected account id if no account id is given
     * @private
     * @param accountId
     * @returns
     */
    _getAccountId(accountId?: Number | null): Number | null {
      let currentAccountId = null
      const authStore = useAuthenticationStore()

      // If currentAccountId is given, then get it
      if (accountId) {
        currentAccountId = accountId
      }
      // If currentAccountId not given AND authenticated user exists, then get authenticated user account id
      else if (authStore.user) {
        currentAccountId = authStore.user.account.id
      }

      return currentAccountId
    },

    contactHubspot(form: IContactForm, successCallback: () => void) {
      OnboardingDataService.sendContactRequest(form).then(
        (res) => {
          successCallback()
        },
        (e) => {
          const authStore = useAuthenticationStore()
          const alertStore = useAlertStore()
          switch (e.response.status) {
            case 401:
              break
            case 500:
              {
                alertStore.push(AlertType.error, e.response.data.message, i18n.global.t("common.errors.default"))
                const token = e.response.data.data.token
                this.router.push({ name: "auth", query: { token: token } })
              }
              break
            default:
              alertStore.push(AlertType.error, i18n.global.t("common.errors.default"), e.message)
              break
          }
        }
      )
    },
  },

  persist: {
    enabled: true,
  },
})
