import type { RouteRecordRaw } from "vue-router"

const onboardingRoutes: RouteRecordRaw[] = [
  {
    path: "/onboarding/freemium",
    name: "onboarding-freemium",
    component: () => import("@/modules/onboarding/views/Introduction.vue"),
    meta: {
      hideIntercom: true,
    },
  },
  {
    path: "/onboarding/begin",
    name: "onboarding-begin",
    component: () => import("@/modules/onboarding/views/Begin.vue"),
    meta: {
      hideIntercom: true,
    },
  },
  {
    path: "/onboarding/establishment",
    name: "onboarding-establishment",
    component: () => import("@/modules/onboarding/views/Establishment.vue"),
    meta: {
      hideIntercom: true,
    },
  },
  {
    path: "/onboarding/analyse",
    name: "onboarding-result",
    component: () => import("@/modules/onboarding/views/Result.vue"),
    meta: {
      transition: "fade",
      hideIntercom: true,
    },
  },
  {
    path: "/onboarding/howtocreatemyaccount",
    name: "onboarding-account",
    component: () => import("@/modules/onboarding/views/Account.vue"),
    meta: {
      hideIntercom: true,
    },
  },
  {
    path: "/onboarding/contactus/:variant?", // variant: ContactVariant
    name: "onboarding-contact",
    component: () => import("@/modules/onboarding/views/Contact.vue"),
    meta: {
      transition: "fade",
      hideIntercom: true,
    },
  },
  {
    path: "/onboarding/contactend",
    name: "onboarding-contact-end",
    component: () => import("@/modules/onboarding/views/ContactEnd.vue"),
    meta: {
      transition: "fade",
      hideIntercom: true,
    },
  },
]

export default onboardingRoutes
