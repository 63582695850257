import type { RouteRecordRaw } from "vue-router"

const authenticationRoutes: RouteRecordRaw[] = [
  {
    path: "/authentication",
    name: "auth",
    component: () => import("@/views/Authentication.vue"),
    meta: {
      transition: "fade",
      hideIntercom: true,
    },
  },
  {
    path: "/inscription/:account?",
    name: "auth-register",
    component: () => import("@/modules/authentication/views/Inscription.vue"),
    meta: {
      transition: "fade",
      hideIntercom: true,
    },
  },
  {
    path: "/google/callback",
    name: "google",
    component: () => import("@/modules/authentication/views/GoogleValidation.vue"),
    meta: {
      transition: "fade",
      hideIntercom: true,
    },
  },
  {
    path: "/inscription/validation/:token", // validation de l'email après l'inscription (utilisé dans l'api)
    name: "auth-validate",
    component: () => import("@/modules/authentication/views/ValidateEmail.vue"),
    meta: {
      transition: "fade",
      hideIntercom: true,
    },
  },
  {
    path: "/first-connexion/:token", // création du mot de passe après invitation (utilisé dans l'api)
    name: "auth-first-connexion",
    component: () => import("@/modules/authentication/views/FirstConnexion.vue"),
    meta: {
      hideIntercom: true,
    },
  },
  {
    path: "/authentication/resetpassword/email",
    name: "auth-reset-email",
    component: () => import("@/modules/authentication/views/EmailReset.vue"),
    meta: {
      hideIntercom: true,
    },
  },
  {
    path: "/authentication/resetpassword/validation/:token", // réinitialisation du mot de passe (mot de passe oublié) (utilisé dans l'api)
    name: "auth-reset-password",
    component: () => import("@/modules/authentication/views/PasswordReset.vue"),
    meta: {
      hideIntercom: true,
    },
  },
  {
    path: "/authentication/contact",
    name: "auth-contact",
    component: () => import("@/modules/authentication/views/Contact.vue"),
    meta: {
      transition: "fade",
      hideIntercom: true,
    },
  },
  {
    path: "/unsubscribe/:token",
    name: "unsubscribe",
    component: () => import("@/modules/authentication/views/Unsubscribe.vue"),
    meta: {
      transition: "fade",
      hideIntercom: true,
    },
  },
]

export default authenticationRoutes
