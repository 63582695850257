import http from "@/http-common"
import type {ICheckConnectDiffuser, IUrlDiffuser, IDisonnectDiffuser, IConnectDiffuser} from "@/types/Diffuser.types"

class DiffuserDataService {
  /**
   * Retrieve the given diffuser type url connexion for the given establishment
   * Response can give null
   *
   * @param {IUrlDiffuser} connect
   * @param headers
   * @returns
   */
  getUrl(connect: IUrlDiffuser, headers: {}): Promise<any> {
    return http.post(`auth/diffusers/url-diffuser-connection`, connect, { headers: headers })
  }

  /**
   * Disconnect given platform
   *
   * @param {IDisonnectDiffuser} disconnect
   * @param headers
   * @returns
   */
  disconnect(disconnect: IDisonnectDiffuser, headers: {}): Promise<any> {
    return http.post(`auth/diffusers/disconnect`, disconnect, { headers: headers })
  }

  /**
   * Check if diffuser type connexion is successful
   *
   * @param {ICheckConnectDiffuser} check
   * @param headers
   * @returns
   */
  checkConnexionStatus(check: ICheckConnectDiffuser, headers: {}): Promise<any> {
    return http.post(`auth/diffusers/check-connect-status`, check, {
      headers: headers,
    })
  }

  /**
   * Get all diffuser for listing view to show sync status
   *
   * @param id
   * @returns
   */
  getEstablishmentStatus(id: number, headers: {}): Promise<any> {
    return http.get(`/auth/diffusers/status/${id}`, { headers: headers })
  }

  /**
   * Connect diffuser to establishment
   *
   * @param connect
   * @param headers
   * @returns
   */
  connect(connect: IConnectDiffuser, headers: {}): Promise<any> {
    return http.post(`/auth/diffusers/connect`, connect, { headers: headers })
  }
}

export default new DiffuserDataService()
