import type { RouteRecordRaw } from "vue-router"
import auth from "@/middlewares/auth"
import publication from "@/middlewares/publication"
import { PageTitleParamsType } from "@/types/Router.types"

const publicationRoutes: RouteRecordRaw[] = [
  {
    path: "/publications",
    name: "publication-management",
    component: () => import("@/views/publications/PublicationsManagement.vue"),
    meta: {
      middleware: [auth, publication],
      title: "publications.page_title",
    },
  },
  {
    path: "/publications/form/:type/:id?",
    name: "publication-form",
    component: () => import("@/views/publications/PublicationsCreate.vue"),
    meta: {
      middleware: [auth],
      title: "publications/form.page_title",
      hasTitleParams: {
        type: PageTitleParamsType.KEY,
        name: "type",
      },
    },
  },
]

export default publicationRoutes
